<!--
 * @Author: Neko
 * @Date: 2021-01-11 11:33:23
 * @LastEditTime: 2021-09-30 17:17:17
 * @LastEditors: Please set LastEditors
-->
<template>
  <div class="cart__container">
    <div class="cart__wrap">
      <div class="header">
        <div class="header__wrap">
          <h2 class="header__title">
            <span class="title">{{ $t('cart.spCart') }}</span>
          </h2>
        </div>
      </div>

      <div class="body">
        <template v-if="cartList.length">
          <div v-for="(item, index) in cartList" :key="item.id" class="body__wrap" :class="{ white: index % 2 === 1 }">

            <div class="body__wrap-restrict">
              <div class="left">
                <div class="left-wrap">
                  <div class="body__header">
                    <div class="checkbox-wrap">
                      <el-checkbox
                        class="checkbox"
                        :disabled="isDisabledCheckbox[index]||item.list.some((i)=>i.cartStatus!==1||i.goodsStatus!==1||i.isBuyStatus!==1)"
                        :value="getSelectedAll(index)"
                        @change="onChangeSelectAllCheckbox($event, index)"
                      />

                      <span class="title">{{ item.storeName }}</span>
                    </div>

                    <a class="delete-btn" href="javascript:void(0)" @click="onClickDeleteAllBtn(index)">{{ $t('cart.allDel') }}</a>
                  </div>

                  <ul class="body__goods-list">
                    <li v-for="(inner) in item.list" :key="inner.id" class="item">
                      <div class="left">
                        <el-checkbox
                          :value="(selectedList[index] || []).includes(inner)"
                          :disabled="inner.cartStatus !== 1||inner.goodsStatus!==1||inner.isBuyStatus!==1"
                          @change="onChangeCheckbox($event, inner, index)"
                        />
                      </div>

                      <div class="right">
                        <GoodsItem
                          :islose="inner.isBuyStatus"
                          :data="inner"
                          :disabled="isLoading"
                          :currency="inner.goodsUnit"
                          @change="onChangeNumber($event, $event, inner.cartId)"
                          @delete="onClickDeleteBtn(inner)"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="right">
                <div class="right-wrap">
                  <h3 class="price__header">{{ $t('cart.priceInfo') }}</h3>

                  <div class="price__detail">
                    <dl class="item">
                      <dt>{{ $t('cart.orderTotalPrc') }}</dt>
                      <dd>{{ currencyUnit }}{{ $convertNumberFloat(getSumTotal(index)) }}</dd>
                    </dl>
                  </div>

                  <div class="price__button">
                    <el-button type="primary" class="button" @click="onClickOrderBtn(index)">{{ $t('cart.clickOrder') }}</el-button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </template>
        <template v-else>
          <div class="body__wrap">
            <Empty class="empty" />
          </div>
        </template>
        <!-- <el-table
          class="cart__table"
          :data="tableData"
          header-row-class-name="header-class"
          row-class-name="row-class"
        >
          <el-table-column align="left" width="69px">
            <template #header>
              <div class="check-all">
                <el-checkbox :disabled="isDisabledCheckbox" :value="isSelectAll" @change="onChangeSelectAllCheckbox">
                  <span class="label">全选</span>
                </el-checkbox>
              </div>
            </template>

            <template #default="scope">
              <div class="check-content">
                <el-checkbox :value="selectedList.includes(scope.row)" @change="onChangeCheckbox($event, scope)" />
              </div>
            </template>
          </el-table-column>

          <el-table-column label="商品" align="center" width="644px">
            <template #default="scope">
              <div class="goods-info">
                <el-image class="image" :src="scope.row.goodsPicUrl" lazy />

                <div class="name">
                  {{ scope.row.commonName }}
                </div>

                <div class="right">
                  <dl class="format">
                    <dt />
                    <dd>{{ scope.row.skuValue }}</dd>
                  </dl>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="单价" prop="priceBig">
            <template #default="scope">
              {{ $convertNumberFloat(scope.row.priceBig) }}
            </template>
          </el-table-column>

          <el-table-column label="数量" width="216px">
            <template #default="scope">
              <el-input-number
                v-model="scope.row.count"
                :min="1"
                :max="scope.row.availableQty"
                size="small"
                :precision="0"
                :disabled="isLoading"
                @change="onChangeNumber($event, $event, scope.row.cartId)"
              />
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template #default="scope">
              <el-button class="delete-btn" type="text" @click="onClickDeleteBtn(scope)">删除</el-button>
            </template>
          </el-table-column>
        </el-table> -->
      </div>

      <!-- <div class="footer">
        <div class="left">
          <el-checkbox :disabled="isDisabledCheckbox" :value="isSelectAll" @change="onChangeSelectAllCheckbox">
            <span class="label">全选</span>
          </el-checkbox>

          <el-button class="button" type="text" @click="onClickDeleteAllBtn">全部删除</el-button>
        </div>
        <div class="right">
          <dl class="sum">
            <dt>合计（不含运费）：</dt>
            <dd>{{ $convertNumberFloat(sumTotal) }}</dd>
          </dl>

          <el-button class="submit" @click="onClickOrderBtn">结算</el-button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch, computed, onMounted } from '@vue/composition-api'
import { Message, MessageBox } from 'element-ui'

import GoodsItem from '@/components/Common/GoodsItem.vue'
import Empty from '@/components/Common/Empty.vue'
import { getDomainPrefix } from '@/utils'
import { useRequest } from '@/utils/request'

export default {
  name: 'Cart',

  components: {
    GoodsItem,
    Empty
  },

  setup(_, { root }) {
    const router = root.$router
    const set = root.$set
    const $t = root.$jst

    const data = reactive({
      cartList: [],

      tableData: [],

      operateData: {
        count: '',
        id: ''
      },

      selectedList: [],

      nowDeleteRow: null,
      nowDeleteNumber: -1,

      currencyId: computed(() => root.$store.state.app.currencyItem?.id),
      countryId: computed(() => root.$store.state.app.countryItem?.id),
      currencyUnit: computed(() => root.$store.state.app.currencyItem?.currencyUnit)
    })

    const { data: cartListData, fetch: getCartListFetch } = useRequest('cart/getCartList', {
      data: computed(() => ({
        domainPrefix: getDomainPrefix, // process.env.VUE_APP_SHOP_DOMAIN
        currencyId: data.currencyId,
        countryId: data.countryId
      })),

      immediate: false
    })

    watch(() => data.currencyId, _ => {
      if (data.currencyId && data.countryId) {
        getCartListFetch()
      }
    })

    watch(() => data.countryId, _ => {
      if (data.currencyId && data.countryId) {
        getCartListFetch()
      }
    })

    watch(() => cartListData.value, result => {
      if (result.length) {
        data.cartList = result

        data.selectedList = result.map(current => ([]))
      } else {
        data.cartList = []
      }
    })

    const { fetch, isLoading } = useRequest('cart/handleUpdateCartAction', {
      data: computed(() => data.operateData),
      immediate: false
    })

    const onChangeNumber = (current, old, id) => {
      if (!current) {
        data.operateData.count = 1
      } else {
        data.operateData.count = current
      }

      data.operateData.id = id
      fetch()
    }

    const onChangeCheckbox = (value, item, index) => {
      if (value) {
        if (!data.selectedList[index]) {
          set(data.selectedList, index, [])
        }
        data.selectedList[index].push(item)
      } else {
        const foundIndex = data.selectedList[index].findIndex(current => current === item)

        if (foundIndex > -1) {
          data.selectedList[index].splice(foundIndex, 1)
        }
      }
    }

    const isSelectAll = computed(() => {
      return data.selectedList.length === data.tableData.length
    })

    const getSelectedAll = index => {
      const length = (data.selectedList[index] || []).length
      if (length === 0) return false
      return length === (data.cartList[index].list || []).filter(current => current.cartStatus === 1).length
    }

    const isDisabledCheckbox = computed(() => {
      return data.cartList.map(current => !current.list.length)
      // return !data.tableData.length
    })

    const onChangeSelectAllCheckbox = (value, index) => {
      if (value) {
        set(data.selectedList, index, data.cartList[index].list.filter(current => current.cartStatus !== -1).map(current => current))
      } else {
        set(data.selectedList, index, [])
      }
    }

    const { fetch: handleDeleteCartFetch } = useRequest('cart/handleDeleteCartAction', {
      data: computed(() => ({
        ids: data.nowDeleteRow ? [data.nowDeleteRow.cartId] : data.nowDeleteNumber > -1 && data.selectedList[data.nowDeleteNumber].map(current => current.cartId)
      })),
      immediate: false,

      onSuccess() {
        data.nowDeleteRow = null
        data.nowDeleteNumber = -1
        Message.success($t('cart.operationSuccess'))
        getCartListFetch()
      }
    })

    const onClickDeleteBtn = (item) => {
      data.nowDeleteRow = item
      data.nowDeleteNumber = -1

      MessageBox.confirm($t('cart.isDelGood'), $t('cart.tips'))
        .then(() => {
          handleDeleteCartFetch()
        })
        .catch(() => {
        })
    }

    const onClickDeleteAllBtn = index => {
      data.nowDeleteRow = null
      data.nowDeleteNumber = index

      if (!(data.selectedList[index] || []).length) {
        Message.error($t('cart.leastGood'))
        return
      }

      MessageBox.confirm($t('cart.whetherDel'), $t('cart.tips'))
        .then(() => {
          handleDeleteCartFetch()
        })
        .catch(() => {})
    }

    const sumTotal = computed(() => {
      return data.tableData.reduce((prev, current) => {
        if (!data.selectedList.includes(current)) return prev
        return prev + (current.count || 0) * current.priceBig
      }, 0)
    })

    const getSumTotal = index => {
      return data.cartList[index].list.reduce((prev, current) => {
        if (!(data.selectedList[index] || []).includes(current)) return prev
        return prev + (current.count || 0) * current.priceBig
      }, 0)
    }

    const onClickOrderBtn = index => {
      if (!data.selectedList[index].length) {
        Message.error($t('cart.leastGood'))
        return
      }

      if (!data.selectedList[index].every(current => current.count)) {
        return Message.error($t('cart.isNumber'))
      }

      router.push({ name: 'OrderSubmit', query: { cart_ids: data.selectedList[index].map(current => current.cartId).join(',') }})
    }

    onMounted(() => {
      if (data.currencyId && data.countryId) {
        getCartListFetch()
      }
    })

    return {
      ...toRefs(data),
      isLoading,
      isSelectAll,
      sumTotal,
      isDisabledCheckbox,

      getSelectedAll,
      getSumTotal,

      onChangeNumber,
      onChangeCheckbox,
      onChangeSelectAllCheckbox,
      onClickDeleteAllBtn,
      onClickDeleteBtn,
      onClickOrderBtn
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

.cart__container {
  min-width: 1200px;
  width: 96.9%;
  max-width: 1600px;
  margin: 0 auto;
  box-shadow: 0px 17px 102px 15px rgba(232, 232, 234, 0.95);
}

.cart__wrap {
  // width: 1200px;
  // padding: 45px 0 100px;
  // margin: 0 auto;

  .header {
    position: relative;
    z-index: 2;
    background: #fff;

    .header__wrap {
      position: relative;
      z-index: 2;
      background: #fff;
    }

    .header__title {
      width: 1200px;
      margin: 0 auto;
      letter-spacing: 2px;
      font-size: 18px;

      .title {
        display: inline-block;
        padding: 32px 0 26px;
        border-bottom: 2px solid #0049AC;
        color: #0D5CAB;
      }

    }

  }

  .body {
    .body__wrap {
      &.white {
        background: #fff;
      }

      .body__wrap-restrict {
        display: flex;
        justify-content: space-between;
        width: 1200px;
        padding: 21px 0;
        margin: 0 auto;
      }

      .left {
        width: 774px;
        flex-shrink: 0;
      }

      .body__header {
        display: flex;
        justify-content: space-between;
        padding-right: 40px;
        margin-bottom: 21px;

        .checkbox-wrap {
          // margin-left: 15px;
          line-height: 30px;
          font-size: 16px;
          color: #222222;
        }

        .title {
          display: inline-block;
          margin-left: 15px;
        }

        .checkbox {
          width: 30px;
          height: 30px;
        }

        .delete-btn {
          line-height: 30px;
          font-size: 14px;
          text-decoration: underline;
          color: #0049AC;
        }
      }

      .body__goods-list {

        .item {
          display: flex;
          align-items: center;

          .left {
            width: 70px;
            flex-shrink: 0;
          }

          .right {
            width: 100%;
          }
        }
      }

      .right {
        // margin-left: 22px;

        .right-wrap {
          width: 390px;
          padding: 32px 40px;
          border-radius: 8px;
          background: #fff;
          box-shadow: 0px 6px 12px 0px #E8E8EA;

          .price__header {
            padding-bottom: 22px;
            border-bottom: 1px solid #0049AC;
            font-size: 16px;
            font-weight: bolder;
            color: #0049AC;
          }

          .price__detail {
            margin: 32px 0;

            .item {
              display: flex;
              justify-content: space-between;

              dt {
                font-size: 16px;
                font-weight: bolder;
                color: #222222;
              }

              dd {
                font-size: 16px;
                font-weight: bolder;
                color: #FF2900;
              }
            }
          }

          .price__button {
            padding: 0 58px;
            margin-top: 81px;

            .button {
              width: 100%;
              height: 50px;
              border: 1px solid #0049AC;
              border-radius: 25px;
              background: #0049AC;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.empty {
  width: 1200px;
}

.cart__table {
  .image {
    width: 105px;
    height: 105px;
  }

  .goods-info {
    display: flex;
    justify-content: space-between;

    .name {
      width: 231px;
      margin: 0 50px 0 12px;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      @include line-clamp(2);
      color: #222;
    }

    .right {
      width: 129px;
      margin-right: 86px;

      .format {
        display: flex;
        width: 100%;

        dd, dt {
          // display: inline-block;
          font-size: 12px;
          line-height: 22px;
          text-align: left;
          color: #666;
        }

        dt {
          flex-shrink: 0;
        }

        dd {
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  .check-all {
    position: relative;

    .label {
      // position: absolute;
      // margin-left: 16px;
      white-space: nowrap;
    }
  }

  .check-content {
    text-align: center;
  }

  .delete-btn {
    font-size: 14px;
    font-weight: normal;
    color: #1D1E20;
  }

  ::v-deep .header-class {
    th {
      padding: 0;
      overflow: visible;
      background: #F9F9F9;
    }

    .cell {
      font-weight: normal;
      font-size: 12px;
      line-height: 42px;

      color: #1D1E20;
    }

    .el-checkbox__label {
      font-weight: normal;
      font-size: 12px;
      line-height: 42px;

      color: #1D1E20;
    }
  }

  ::v-deep .row-class {
    td {
      padding: 0;
      // border: none;
      // background: #fff;
    }

    .cell {
      padding: 20px 10px 20px;
    }
  }

  &.el-table--enable-row-hover ::v-deep .el-table__body tr:hover > td {
    background-color: #FFF8E1;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 12px;
  margin-top: 48px;
  background: #E5E5E5;

  .left {
    .label {
      display: inline-block;
      margin-left: 5px;
      font-size: 12px;
      font-weight: normal;
      color: #222222;
    }

    .button {
      margin-left: 30px;
      font-size: 12px;
      font-weight: normal;
      color: #222222;
    }
  }

  .right {
    .sum {
      display: inline-block;
      margin-right: 11px;

      dd, dt {
        display: inline-block;
        line-height: 50px;
      }

      dt {
        font-size: 12px;
        color: #222;
        vertical-align: middle;
      }

      dd {
        font-size: 20px;
        font-weight: bolder;
        vertical-align: middle;
        color: #FF2900;

        @include currency;
      }
    }

    .submit {
      width: 130px;
      padding: 0;
      border-radius: 0;
      border: none;
      vertical-align: middle;
      font-size: 20px;
      line-height: 50px;

      background: #FF5000;
      color: #FEFFFF;
    }
  }
}

::v-deep .el-checkbox__input {
  .el-checkbox__inner {
    // width: 30px;
    // height: 30px;

    // background: #fff;

    &::after {
      // display: none;
      // font-size: 30px;
    }
  }
}
</style>
