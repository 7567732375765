<!--
 * @Author: Neko
 * @Date: 2021-03-19 14:55:51
 * @LastEditTime: 2021-09-30 17:19:03
 * @LastEditors: Please set LastEditors
-->
<template>
  <div class="goods-item__container">
    <router-link class="image" :to="{ name: 'GoodsDetail', query: { id: data.goodsId } }" target="_blank">
      <img :src="data.goodsPicUrl">
      <div v-if="data.islose===1" class="lose" />
    </router-link>

    <router-link
      :to="{ name: 'GoodsDetail', query: { id: data.goodsId } }"
      class="title"
      :class="{
        'full-width': !showOperation
      }"
      target="_blank"
    >
      <p class="name">{{ data.commonName }}</p>
      <p class="format">{{ data.skuValue }}</p>
    </router-link>

    <div class="price">
      <span class="price-box">
        {{ currency }}{{ data.priceBig }}
      </span>

      <span v-if="!showOperation" class="count-box">
        x{{ data.count }}
      </span>
    </div>

    <div v-if="showOperation" class="input">
      <InputNumber
        v-model="data.count"
        :min="1"
        :max="data.availableQty"
        :precision="0"
        :disabled="data.cartStatus === -1 || disabled"
        @change="onChangeNumber"
      />
    </div>

    <div v-if="showOperation" class="delete">
      <i class="el-icon-error" @click="onChangeDeleteBtn" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import InputNumber from '@/components/Common/InputNumber.vue'

export default defineComponent({
  name: 'GoodsItem',

  components: {
    InputNumber
  },

  emits: ['change'],

  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    // 是否失效，true为失效，false为未失效
    islose: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    showOperation: {
      type: Boolean,
      default: true
    },

    currency: {
      type: String,
      default: ''
    }
  },

  setup(_, { emit }) {
    const onChangeNumber = (newValue, oldValue) => {
      if (newValue === undefined) return

      if (typeof newValue === 'string' && newValue.trim() === '') {
        return
      }

      emit('change', newValue, oldValue)
    }

    const onChangeDeleteBtn = _ => {
      emit('delete')
    }

    return {
      onChangeNumber,
      onChangeDeleteBtn
    }
  }
})
</script>

<style lang="scss" scoped>
.goods-item__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 18px 42px 18px 20px;
  margin-bottom: 20px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 6px 12px 0px #E8E8EA;

  & > * {
    flex-shrink: 0;
  }

  .image {
    position: relative;
    width: 100px;
    height: 86px;
    padding: 9px 16px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #F8F9FA;

    img {
      width: 100%;
      height: 100%;
    }
    .lose{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('https://cdn.jsdelivr.net/gh/chen-xing/figure_bed_02/cdn/20210930150544203.png') center no-repeat;
    }
  }

  .title {
    width: 205px;
    padding: 12px;
    margin-left: 33px;

    &.full-width {
      width: 100%;
      flex-shrink: 1;
    }

    .name {
      @include line-clamp(2);
      font-size: 14px;
      line-height: 22px;
      color: #222222;
    }

    .format {
      font-size: 12px;
      line-height: 22px;
      color: #999999;
    }
  }

  .price {
    flex-shrink: 0;
    width: 119px;
    // margin-left: 52px;
    font-size: 14px;
    color: #6AABED;

    .price-box, .count-box {
      display: block;
      text-align: right;
    }

    .count-box {
      color: #AAAAAA;
    }

  }

  .input {
    margin-left: 30px;
  }

  .delete {
    margin-left: 30px;
    font-size: 32px;
    color: #DDDDDD;
  }
}
</style>
