<!--
 * @Author: Neko
 * @Date: 2021-03-19 15:12:25
 * @LastEditTime: 2021-03-26 11:43:58
 * @LastEditors: Neko
-->
<template>
  <el-input-number
    v-model="count"
    class="input-number__container"
    :min="min"
    :precision="precision"
    :max="max"
    :disabled="disabled"
    size="mini"
    @change="onChangeNumber"
  />
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'InputNumber',

  emits: ['change'],

  props: {
    value: {
      type: [Number, String],
      default: 1
    },

    max: {
      type: Number,
      default: 0
    },

    min: {
      type: Number,
      default: 0
    },

    precision: {
      type: Number,
      default: 0
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    const data = reactive({
      count: 0
    })

    watch(() => props.value, res => {
      data.count = res
    })

    onMounted(() => {
      data.count = props.value
    })

    const onChangeNumber = (newValue, oldValue) => {
      emit('input', newValue)
      emit('change', newValue, oldValue)
    }

    return {
      ...toRefs(data),

      onChangeNumber
    }
  }
})
</script>

<style lang="scss">
.input-number__container {
  width: 90px;
  border: 1px solid #eee;
  border-radius: 20px;
  line-height: 40px;
  overflow: hidden;

  .el-input {

    .el-input__inner {
      height: 40px;
      line-height: 40px;
      border-radius: 20px;
      border: none;
    }
  }

  .el-input-number__decrease {
    border-radius: 0;
    border: none;
    font-weight: bolder;
    background: #fff;
    color: #AEAFB6;
  }
  .el-input-number__increase {
    border-radius: 0;
    border: none;
    font-weight: bolder;
    background: #fff;
    color: #AEAFB6;
  }
}
</style>
